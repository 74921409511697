import React, { FormEvent, useState } from "react";
import "./modal-edit-category.sass";
import { AdminApi, AppCategoryResponse, CreateAppCategoryRequest, UpdateAppCategoryRequest } from "../../openapi";
import { getServiceConfig } from "../../util/helper";

interface Props {
  setIsLoading: (b: boolean) => void;
  refreshCategories: () => void;
  isEdit: boolean;
  isActive: boolean;
  setIsModalActive: (b: boolean) => void;
  editCategory?: AppCategoryResponse;
  resetAll: () => void;
}

const ModalEditCategory = ({ setIsLoading, refreshCategories, isEdit, isActive, setIsModalActive, editCategory, resetAll }: Props) => {
  const [name, setName] = useState(editCategory?.name ? editCategory.name : "");
  const [sort, setSort] = useState<number>(editCategory?.sort ? editCategory.sort : 0);

  const [errorMessage, setErrorMessage] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const getAppsCategoriesService = async () => {
    return new AdminApi(await getServiceConfig());
  };
  const closeModal = () => {
    setIsModalActive(false);
    resetAll();
  };

  const onSortChanged = (value?: string) => {
    setErrorMessage(null);
    if (value) {
      setSort(Number(value));
    }
  };

  const createCategoryRequest = async (requestParams: CreateAppCategoryRequest) => {
    const appsCategoriesData = await getAppsCategoriesService();
    return await appsCategoriesData.createAppCategory(requestParams);
  };

  const updateCategoryRequest = async (requestParams: UpdateAppCategoryRequest) => {
    const appsCategoriesData = await getAppsCategoriesService();
    return await appsCategoriesData.updateAppCategory(requestParams);
  };

  const updateCategory = async (e: FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    if (name) {
      try {
        updateCategoryRequest({
          id: editCategory!.id,
          name: name,
          sort: sort,
        })
          .then(response => {
            setIsLoading(true);
            refreshCategories();
            closeModal();
            setIsSubmitting(false);
          })
          .catch(error => {
            setIsSubmitting(false);
            console.log(error);
          });
      } catch (error: any) {
        setIsSubmitting(false);
        console.log(error.message);
        setErrorMessage(error.message);
      }
    } else {
      setIsSubmitting(false);
      console.log("input value missing");
    }
  };

  const createCategory = async (e: FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    if (name) {
      try {
        createCategoryRequest({
          name: name,
          sort: sort,
        })
          .then(response => {
            setIsLoading(true);
            refreshCategories();
            closeModal();
            setIsSubmitting(false);
          })
          .catch(error => {
            setIsSubmitting(false);
            console.log(error);
          });
      } catch (error: any) {
        setIsSubmitting(false);
        console.log(error.message);
        setErrorMessage(error.message);
      }
    } else {
      setIsSubmitting(false);
      console.log("input value missing");
    }
  };

  return (
    <div className={`modal edit-contact ${isActive ? "is-active" : ""}`}>
      <div className="modal-background" onClick={closeModal}></div>
      <form onSubmit={isEdit ? updateCategory : createCategory}>
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">{isEdit ? "Edit" : "Create"} Category</p>
            <button className="delete" onClick={closeModal} aria-label="close"></button>
          </header>
          <section className="modal-card-body">
            <div className="has-text-danger">{errorMessage}</div>
            <div className="form-item">
              <label className="label">Name *</label>
              <input
                className="input"
                required
                type="text"
                value={name}
                onChange={e => {
                  setErrorMessage(null);
                  setName(e.target.value);
                }}
              />
            </div>
            <div className="form-item">
              <label className="label">Sort order</label>
              <input className="input" type="text" required value={sort} onChange={e => onSortChanged(e.target.value)} />
            </div>
          </section>
          <footer className="modal-card-foot">
            <button disabled={isSubmitting} type="submit" className="button is-primary">
              {isEdit ? "Update" : "Create"} Category
            </button>
          </footer>
        </div>
      </form>
    </div>
  );
};

export default ModalEditCategory;
