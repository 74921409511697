import { FormEvent, useCallback, useEffect, useMemo, useState } from "react";
import { AdminApi, AppCategoryResponse, AppDescriptionResponse, AppLevel, AppResponse, AppResponseLite, CreateAppRequest, UpdateAppRequest } from "../../openapi";
import { ValueType } from "react-select";
import { getServiceConfig } from "../../util/helper";

export interface AppWithCategories extends AppResponse {
  categories: AppCategoryResponse[];
}

export interface ISelectLabelOption {
  label: string;
  value: number;
}

interface CategorySelection {
  id: string;
  name: string;
  original?: boolean;
}

interface ILabelsState {
  [key: string]: ValueType<ISelectLabelOption, true> | null
}

interface IGroupedLabelOption {
  [key: string]: ISelectLabelOption[]
}

export interface IAppLevelOption {
  label: string;
  value: AppLevel;
}

const getApiService = async () => {
  return new AdminApi(await getServiceConfig());
};

export const useModalEditApp = (existingApp: AppResponseLite | undefined, categoryOptions: AppCategoryResponse[] | undefined, onClose: (refreshApps: boolean) => void) => {
  const appLevelOption: IAppLevelOption[] = useMemo(() => [
    {
      label: "Pinwheel Approved",
      value: AppLevel.PinwheelApproved,
    },
    {
      label: "Slightly Out of Bounds",
      value: AppLevel.SlightlyOutOfBounds,
    },
    {
      label: "Violates Guidelines",
      value: AppLevel.ViolatesGuidelines,
    },
    {
      label: "Untested by Pinwheel",
      value: AppLevel.UntestedByPinwheel,
    },
    {
      label: "Core Apps",
      value: AppLevel.CoreApps,
    },
  ], []);

  const [name, setName] = useState(existingApp?.name ?? "");
  const [image, setImage] = useState("");
  const [packageName, setPackageName] = useState("");
  const [description, setDescription] = useState("");
  const [formattedDescription, setFormattedDescription] = useState("");
  const [neverDisable, setNeverDisable] = useState(false);
  const [isBanned, setIsBanned] = useState(false);
  const [hideForNewUsers, setHideForNewUsers] = useState(false);
  const [madePublicOn, setMadePublicOn] = useState<string | undefined>(undefined);
  const [playStoreURL, setPlayStoreURL] = useState("");
  const [developerSite, setDeveloperSite] = useState("");
  const [shortDescription, setShortDescription] = useState("");
  const [selectAppLevel, setSelectAppLevel] = useState<IAppLevelOption | undefined>(undefined);
  const [category, setCategory] = useState<CategorySelection[]>([]);

  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [removedCategory, setRemovedCategory] = useState<CategorySelection[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [appDescriptions, setAppDescriptions] = useState<AppDescriptionResponse[]>([]);
  const [labelsState, setLabelsState] = useState<ILabelsState>({})
  const [existingAppModel, setExistingAppModel] = useState<AppWithCategories | undefined | null>();

  const createRequestObj = useCallback((appDescIds: number[]): UpdateAppRequest => {
    const result =  {
      id: existingApp?.id ?? "",
      name,
      packageName: packageName.trim().length > 0 ? packageName : undefined,
      description,
      formattedDescription: formattedDescription === "" ? undefined : formattedDescription,
      img: image === "" ? undefined : image,
      neverDisable,
      hideForNewUsers,
      isBanned,
      shortDescription,
      playStoreURL,
      developerSite,
      madePublicOn: madePublicOn && madePublicOn.length > 0 ? new Date(madePublicOn) : undefined,
      level: selectAppLevel?.value,
      appDescriptionIds: appDescIds
    };

    if (appDescIds.length > 0) {
      result.appDescriptionIds = appDescIds;
    }
    return result;
  }, [description, developerSite, existingApp?.id, formattedDescription, hideForNewUsers, image, isBanned, madePublicOn, name, neverDisable, packageName, playStoreURL, selectAppLevel?.value, shortDescription])

  const appUpdate = useCallback(async (requestParams: UpdateAppRequest) => {
    const appService = await getApiService();
    return await appService.updateApp(requestParams);
  }, []);

  const createApp = useCallback(async (requestParams: CreateAppRequest) => {
    const appService = await getApiService();
    return await appService.createApp(requestParams);
  }, []);

  const updateAppCategories = useCallback(async (appId: string, categoryIdsToAdd: string[], categoryIdsToRemove?: string[]) => {
    const appService = await getApiService();
    return await appService.modifyAppCategories({
      appId: appId,
      addIds: categoryIdsToAdd,
      removeIds: categoryIdsToRemove,
    });
  }, []);

  const handleApiError = useCallback(async(error: unknown) => {
    if (error instanceof Response && error.status === 400) {
      const body = JSON.parse(await error.text());
      setErrorMessage(body.details);
    } else {
      setErrorMessage("Error updating app");
    }
    console.error(error);
  }, []);

  const getAppsByIds = useCallback(async (appId: string): Promise<AppWithCategories | null> => {
    try {
      const appsService = await getApiService();
      const result = await appsService.getAppsByIds(appId, true);
      const apps = result.data.apps;
      if (apps.length === 0) {
        return null;
      }

      const categories = apps[0].appCategoryIds
          .map(id => categoryOptions?.find((category) => category.id === id))
          .filter(cat => cat !== undefined) as AppCategoryResponse[];
      return {...apps[0], categories};
    } catch (error) {
      console.log(error);
      throw error;
    }
  }, [categoryOptions]);

  const initializeForm = useCallback((app: AppWithCategories | null) => {
    setName(app?.name ?? "");
    setImage(app?.img ?? "");
    setPackageName(app?.packageName ?? "");
    setDescription(app?.description ?? "");
    setFormattedDescription(app?.formattedDescription ?? "");
    setNeverDisable(app?.neverDisable ?? false);
    setIsBanned(app?.isBanned ?? false);
    setHideForNewUsers(app?.hideForNewUsers ?? false);
    setMadePublicOn(app?.madePublicOn ? new Date(app.madePublicOn).toISOString().split("T")[0] : undefined);
    setPlayStoreURL(app?.playStoreURL ?? "");
    setDeveloperSite(app?.developerSite ?? "");
    setShortDescription(app?.shortDescription ?? "");
    setSelectAppLevel(
      appLevelOption.find(item => (item.value).toString() === app?.level) || undefined,
    );
    setCategory( 
      app?.categories.map(category => {
        return { original: true, name: category.name, id: category.id };
      }) ?? []
    );
    setExistingAppModel(app);
  }, [appLevelOption]);

  useEffect(() => {
    if (existingApp !== undefined && existingAppModel === undefined) {
      (async () => {
        const appModel = await getAppsByIds(existingApp.id)
        initializeForm(appModel);
      })()
    }
  }, [getAppsByIds, existingApp, existingAppModel, initializeForm]);

  const handleSubmit = useCallback(async (e: FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    if (name && category) {
      try {
        const appDescIds: number[] = [];
        const submittedAppDescTypes = Object.keys(labelsState);
        if (submittedAppDescTypes.length > 0) {
          submittedAppDescTypes.forEach(type => {
              labelsState[type]?.forEach(selectedItem => appDescIds.push(selectedItem.value));
          })
        }

        const response = await createApp(createRequestObj(appDescIds));

        // create category relations
        const appCategoriesToAdd: string[] = [];
        category.forEach(cat => {
          appCategoriesToAdd.push(cat.id);
        });

        try {
          if (appCategoriesToAdd.length > 0) {
            await updateAppCategories(response.data.id, appCategoriesToAdd, []);
          }
          onClose(true);
        } catch (error) {
          handleApiError(error)
        } finally {
          setIsSubmitting(false);
        }
      } catch (error: unknown) {
        handleApiError(error);
        setIsSubmitting(false);
      }
    } else {
      setIsSubmitting(false);
      console.log("input value missing");
    }
  }, [category, createApp, handleApiError, labelsState, name, onClose, createRequestObj, updateAppCategories]);

  const updateApp = useCallback(async (e: FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    if (name && category) {
      try {
        const appDescIds: number[] = [];
        const submittedAppDescTypes = Object.keys(labelsState);
        if (submittedAppDescTypes.length > 0) {
          submittedAppDescTypes.forEach(type => {
              labelsState[type]?.forEach(selectedItem => appDescIds.push(selectedItem.value));
          })
        }

        const response = await appUpdate(createRequestObj(appDescIds));

        // remove category relations
        const appCategoriesToRemove: string[] = [];
        removedCategory.forEach(cat => {
          if (cat.original) {
            appCategoriesToRemove.push(cat.id);
          }
        });

        // create category relations
        const appCategoriesToAdd: string[] = [];
        category.forEach(cat => {
          if (!cat.original) {
            appCategoriesToAdd.push(cat.id);
          }
        });

        try {
          if (appCategoriesToAdd.length > 0 || appCategoriesToRemove.length > 0) {
            await updateAppCategories(response.data.id, appCategoriesToAdd, appCategoriesToRemove);
          }
          onClose(true);
        } catch (error) {
          handleApiError(error);
        } finally {
          setIsSubmitting(false);
        }
      } catch (error: unknown) {
        handleApiError(error);
        setIsSubmitting(false);
      }
    } else {
      setIsSubmitting(false);
      console.log("input value missing");
    }
  }, [appUpdate, category, handleApiError, labelsState, name, onClose, removedCategory, createRequestObj, updateAppCategories]);

  const selectOnChangeHandler = useCallback((selectedList: CategorySelection[], selectedItem: CategorySelection) => {
    setCategory(selectedList);
    const index = removedCategory.findIndex(item => item.id === selectedItem.id);
    if (index !== -1) {
      setRemovedCategory(removedCategory.filter(item => item.id !== selectedItem.id));
    }
  }, [removedCategory]);

  const removeItemHandler = useCallback((_: CategorySelection[], removedItem: CategorySelection) => {
    setRemovedCategory([...removedCategory, removedItem]);
    const catIndex = category.findIndex(item => item.id === removedItem.id);
    if (catIndex !== -1) {
      setCategory(category.filter(item => item.id !== removedItem.id));
    }
  }, [category, removedCategory]);

  const changeFormattedDescription = useCallback((newValue: string) => {
    setErrorMessage(null);
    setFormattedDescription(newValue);
  }, []);

  const onHideApp = useCallback(() => {
    const originallyPublic = existingAppModel?.madePublicOn;
    if (originallyPublic) {
      alert("Please contact Brian Bauman or Rubel to hide an app.  Removing apps could cause problems in Caregiver");
    } else {
      setMadePublicOn(undefined);
    }
  }, [existingAppModel?.madePublicOn])

  const onMakePublicClicked = useCallback(() => {
    if (category.length === 0) {
      alert("To make an app public, you must first select a category");
      return;
    }
    setMadePublicOn(new Date().toISOString().split("T")[0]);
  }, [category.length]);

  const groupLabelsByTypes = useCallback((descriptions: AppDescriptionResponse[]): IGroupedLabelOption => {
    const typesObj: { [key: string]: [{ label: string, value: number }] } = {}
    descriptions.forEach(({type, label, id}: AppDescriptionResponse) => {
      if (typesObj.hasOwnProperty(type)) {
        typesObj[type].push({ label: label, value: id})
      } else {
        typesObj[type] = [{ label: label, value: id }]
      }
    })
    return typesObj
  }, [])

  useEffect(() => {
    async function loadAppDescriptions() {
      const apiService = await getApiService();
      const response = await apiService.getAppDescriptions();
      setAppDescriptions(response.data.descriptions);
    }
    loadAppDescriptions();
  }, []);

  useEffect(() => {
    const existingAppDesc: any = {}
    if (existingAppModel && existingAppModel.appDescriptionIds && existingAppModel.appDescriptionIds.length > 0 && appDescriptions.length > 0) {
      existingAppModel.appDescriptionIds.forEach(existingId => {
        const matchedDesc = appDescriptions.find(item => item.id === existingId);
        if (matchedDesc) {
          if (matchedDesc.type in existingAppDesc) {
            existingAppDesc[matchedDesc.type].push({
              label: matchedDesc.label,
              value: matchedDesc.id
            })
          } else {
            existingAppDesc[matchedDesc.type] = [{
              label: matchedDesc.label,
              value: matchedDesc.id
            }];
          }
        }
      });
      setLabelsState(existingAppDesc);
    }
  }, [appDescriptions, existingAppModel])

  return {
    updateApp,
    handleSubmit,
    existingAppModel,
    errorMessage,
    selectOnChangeHandler,
    removeItemHandler,
    category,
    name,
    setName,
    description,
    setDescription,
    shortDescription,
    setShortDescription,
    selectAppLevel,
    setSelectAppLevel,
    appLevelOption,
    packageName,
    formattedDescription,
    changeFormattedDescription,
    setPackageName,
    image,
    setImage,
    playStoreURL,
    setPlayStoreURL,
    developerSite,
    setDeveloperSite,
    neverDisable,
    setNeverDisable,
    hideForNewUsers,
    setHideForNewUsers,
    isBanned,
    setIsBanned,
    madePublicOn,
    onHideApp,
    onMakePublicClicked,
    isSubmitting,
    setErrorMessage,
    groupLabelsByTypes,
    appDescriptions,
    labelsState,
    setLabelsState
  }
}