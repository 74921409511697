import React from "react";
import { AppCategoryResponse } from "../../openapi";

interface Props {
  category: AppCategoryResponse, 
  removeCategory: (appsCategory: AppCategoryResponse) => void,
  setEditForm: (category: AppCategoryResponse) => void
}

const CategoryList = ({ category, removeCategory,setEditForm }: Props) => {
  
  const remove = (category: AppCategoryResponse) => {
    removeCategory(category);
  }
  return (
    <tr key={category.id}>
      <td>
        <span className="name">{ category.name } || { category.sort }</span>
      </td>
      <td>
        <div className="buttons is-inline is-pulled-right	">
          <button className="button is-text" onClick={() => setEditForm(category)}>Edit</button>
          <button onClick={() => remove(category)} className="button is-text remove">Remove</button>
        </div>
      </td>
    </tr>
  )
};


export default CategoryList;
