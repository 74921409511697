import React from "react";
import { Switch, Route, HashRouter } from "react-router-dom";

import Dashboard from "./routes/dashboard";
import Users from "./routes/users";
import Category from "./routes/category";
import AppDescription from "./routes/AppDescription";

// import OnIt from "./routes/on-it/";
import ForgotPassword from "./routes/forgot-password";
import Login from "./routes/login";
import SignUp from "./routes/sign-up";
import Apps from "./routes/apps";
// import Calendar from "./routes/calendar";

import AuthPage from "./wrappers/auth-page";
import DashboardPage from "./wrappers/dashboard-page";

import { MeProvider } from "./context/me";

import "./styles/main.css";
import "./styles/animate.css";
import "./styles/form.sass";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

function App() {
  return (
    <HashRouter>
      <MeProvider>
        <Switch>
          <Route exact={true} path="/">
            <DashboardPage title="Home">
              <Dashboard />
            </DashboardPage>
          </Route>

          <Route exact={true} path="/caregiver">
            <DashboardPage title="Caregiver">
              <Users />
            </DashboardPage>
          </Route>

          <Route exact={true} path="/app-category">
            <DashboardPage title="Category">
              <Category />
            </DashboardPage>
          </Route>

          <Route exact={true} path="/app-description">
            <DashboardPage title="Description">
              <AppDescription />
            </DashboardPage>
          </Route>

          <Route exact={true} path="/apps">
            <DashboardPage title="Apps">
              <Apps />
            </DashboardPage>
          </Route>

          <Route exact={true} path="/login">
            <AuthPage>
              <Login />
            </AuthPage>
          </Route>
          <Route exact={true} path="/forgot-password">
            <AuthPage>
              <ForgotPassword />
            </AuthPage>
          </Route>
          <Route exact={true} path="/sign-up">
            <AuthPage>
              <SignUp />
            </AuthPage>
          </Route>
        </Switch>
      </MeProvider>
    </HashRouter>
  );
}

export default App;
