import {Auth} from "aws-amplify";
import { Configuration} from "../openapi";

const getServiceConfig = async () => {
   const data = await Auth.currentAuthenticatedUser();

   return new Configuration({
      basePath: (process.env.REACT_APP_REST_API_BASE_URL) ? process.env.REACT_APP_REST_API_BASE_URL : undefined,
      apiKey: () =>{
       return data.signInUserSession.accessToken.jwtToken;
      }
   })
}

const localCompareWithNumbers = (a: string, b: string): number => {
    return a.toLowerCase().localeCompare(b.toLowerCase(), undefined, { numeric: true, sensitivity: "base" })
}

export { getServiceConfig, localCompareWithNumbers };


