import React, { useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { Auth } from "aws-amplify";

import "./login.sass";

function Login() {
  let history = useHistory();
  const [email, setEmail] = useState<string | null>(null);
  const [password, setPassword] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const handleLogin = async () => {
    if (email && password) {
      try {
        const user = await Auth.signIn(email, password);
        localStorage.setItem("ReactAmplify.TokenKey", user.signInUserSession.accessToken.jwtToken);
        console.log("hello");
        history.push("/");
      } catch (error: any) {
        console.log(error.message);
        setErrorMessage(error.message);
      }
    } else {
      setErrorMessage("Please enter email and password");
    }
  };

  return (
    <div className="page-login">
      <h1 className="title">Impekable</h1>
      <p className="subtitle is-5">Welcome back! Please login to your account.</p>
      <p className="subtitle error is-6">{errorMessage}</p>
      <div className="container">
        <div className="form-item">
          <label className="label">Email</label>
          <input
            className="input"
            type="email"
            onChange={e => {
              setErrorMessage(null);
              setEmail(e.target.value);
            }}
          />
        </div>
        <div className="form-item">
          <label className="label">Password</label>
          <input
            className="input"
            type="password"
            onChange={e => {
              setErrorMessage(null);
              setPassword(e.target.value);
            }}
          />
        </div>
        <div className="form-item settings">
          <Link to="forgot-password">Forgot password</Link>
        </div>
        <div className="form-item buttons">
          <button className="button is-primary" onClick={handleLogin}>
            Login
          </button>
          <button className="button" onClick={() => history.push("/sign-up")}>
            Sign up
          </button>
        </div>
      </div>
    </div>
  );
}

export default Login;
