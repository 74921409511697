import React, { useCallback, useEffect, useState } from "react";
import { addUserToGroup, updateUser } from "../../aws/userpool";
import "./modal-edit-user.sass";
import { UserType } from "aws-sdk/clients/cognitoidentityserviceprovider";
import { Auth } from "aws-amplify";
import { AdminApi, CaregiverResponse, UpdateCaregiverDto } from "../../openapi";
import { getServiceConfig } from "../../util/helper";
import axios from "axios";

const getApiService = async () => {
  return new AdminApi(await getServiceConfig());
};


interface EditType extends UserType {
  password: string,
  confirmpassword: string}

interface Props {
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
  getUserList: any,
  resetAll: () => void,
  isChildren: boolean,
  isEdit: boolean,
  editUser: EditType,
  isActive: boolean,
  setIsModalActive: React.Dispatch<React.SetStateAction<boolean>>,
  activeGroup: string
}

const ModalEditUser = ({
  setIsLoading,
  getUserList,
  resetAll,
  isChildren,
  isEdit,
  editUser,
  isActive,
  setIsModalActive,
  activeGroup
}: Props ) => {
  if (!isEdit) {
    editUser = {
      Username: "",
      Attributes: [
        {
          Name: "given_name",
          Value: ""
        },
        {
          Name: "family_name",
          Value: ""
        },
        {
          Name: "email",
          Value: ""
        },
        {
          Name: "phone",
          Value: ""
        }
      ],
      password: "Admin1234#",
      confirmpassword: "Admin1234#"
    };
  }

  const closeModal = () => {
    setIsModalActive(false);
    resetAll();
  };

  const [username, setUserName] = useState(editUser.Username);

  const [firstName, setFirstName] = useState(
    editUser.Attributes?.find(value => value.Name === "given_name")?.Value ?? ""
  );
  const [lastName, setLastName] = useState(
    editUser.Attributes?.find(value => value.Name === "family_name")?.Value ?? ""
  );
  const [email, setEmail] = useState(
    editUser.Attributes?.find(value => value.Name === "email")?.Value ?? ""
  );
  const [phone, setPhone] = useState(
    editUser.Attributes?.find(value => value.Name === "phone_number")?.Value ?? ""
  );
  const [password, setPassword] = useState(editUser.password);
  const [confirmpassword, setConfirmPassword] = useState(editUser.confirmpassword);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [caregiver, setCaregiver] = useState<CaregiverResponse | null>(null);

  const loadCaregiver = useCallback(async () => {
    if (editUser && editUser.Attributes) {
      try {
        const sub = editUser.Attributes.find((attr) => attr.Name === "sub")!.Value!;
        const apiService = await getApiService();
        const { data } = await apiService.getCaregiver(sub);
        setCaregiver(data);
      } catch (e) {
        if (axios.isAxiosError(e) && e.response?.status === 404) {
          // Caregiver not found in our database.
          setCaregiver(null);
        } else {
          // Other error happened while fetching caregiver from our db.
          console.log(e);
          alert("Failed to load billing email");
        }
      }
    }
  }, [editUser]);

  useEffect(() => {
    loadCaregiver();
  }, [loadCaregiver]);

  const createDtoToUpdateEmails = () => {
    const newCgpEmail = email.trim();
    const newBillingEmail = caregiver && caregiver.billingEmail ? caregiver.billingEmail.trim() : "";
    const updateCaregiverDto: UpdateCaregiverDto = {};
    if (newCgpEmail.length > 0) {
      updateCaregiverDto.cgpEmail = newCgpEmail;
    }
    if (newBillingEmail.length > 0) {
      updateCaregiverDto.billingEmail = newBillingEmail;
    }
    return updateCaregiverDto;
  }

  const updateCaregiver = async () => {
    if (caregiver && editUser && editUser.Attributes && (email || caregiver.billingEmail)) {
      setIsSubmitting(true);
      const sub = editUser.Attributes.find((attr) => attr.Name === "sub")!.Value!;
      const apiService = await getApiService();
      const { data } = await apiService.updateCaregiver(sub, createDtoToUpdateEmails());
      setIsSubmitting(false);
      if (data.success) {
        console.log("Emails are saved in DB");
      } else {
        alert("Failed to save emails in DB");
      }
    }
  };

  const handleSignup = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    setIsSubmitting(true);

    if (password !== confirmpassword) {
      setIsSubmitting(false);
      setErrorMessage("Password and confirm password does not match");
      return ""
    }

    if ( password && firstName && username) {
      const params = {
        username,
        password,
        phone,
        confirmpassword,
        attributes: {
          email: email,
          "given_name": firstName,
          "family_name": lastName
        }
      }

      try {
        await Auth.signUp(params).then(res => {
          addUserToGroup(
            {
              Username: res.user.getUsername(),
              GroupName: isChildren ? "child" : "caregiver",
            },
            (err: AWS.AWSError) => {
              setIsLoading(true);
              getUserList(activeGroup);
              closeModal();
              setIsSubmitting(false);
              if (err) {
                console.error(err);
              }
            }
          );
        });
      } catch (error: any) {
        setIsSubmitting(false);
        console.log(error.message);
        setErrorMessage(error.message);
      }
    } else {
      setIsSubmitting(false);
      console.log("input value missing");
    }
  };

  const editUserInfo = async (e: React.SyntheticEvent) => {
    e.preventDefault();

    if (firstName && username) {
      setIsSubmitting(true);
      try {
        updateUser(
          {
            UserAttributes: [
              {
                Name: "given_name",
                Value: firstName
              },
              {
                Name: "family_name",
                Value: lastName
              },
              {
                Name: "email",
                Value: email
              },
              {
                Name: "phone_number" /* required */,
                Value: phone
              },
              {
                Name: "email_verified" /* required */,
                Value: "true",
              },
              // {
              //   Name: "phone_number_verified" /* required */,
              //   Value: "true"
              // },
            ],
            Username: username
          },
          (err: AWS.AWSError) => {
            if (err) {
              console.error(err);
              alert(err.message);
            } else {
              if (caregiver) {
                updateCaregiver().then(() => {
                  setIsLoading(true);
                  getUserList(activeGroup);
                  setIsSubmitting(false);  
                  closeModal();
                }).catch(err => {
                  console.log(err);
                  alert(err.message);
                });
              } else {
                setIsLoading(true);
                getUserList(activeGroup);
                setIsSubmitting(false);
                closeModal();
              }
            }
          }
        );
      } catch (error: any) {
        setIsSubmitting(false);
        console.log(error);
        setErrorMessage(error.message);
      }
    } else {
      setIsSubmitting(false);
      console.log("input value missing");
    }
  };

  return (
    <div className={`modal edit-contact ${isActive ? "is-active" : ""}`}>
      <div className="modal-background" onClick={closeModal}></div>
      <form onSubmit={isEdit ? editUserInfo : handleSignup}>
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">
              {isEdit ? "Edit" : "Create"}{" "}
              {isChildren || activeGroup === "child" ? "Children" : "Caregiver"}
            </p>
            <button className="delete" onClick={closeModal} aria-label="close"></button>
          </header>
          <section className="modal-card-body">
            <div className="has-text-danger">{errorMessage}</div>
            <div className="form-item">
              <label className="label">User Name *</label>
              <input
                className="input"
                type="text"
                required
                value={username}
                onChange={e => {
                  setErrorMessage(null);
                  setUserName(e.target.value);
                }}
                readOnly={isEdit}
              />
            </div>

            <div className="form-item">
              <label className="label">First Name *</label>
              <input
                className="input"
                type="text"
                required
                value={firstName}
                onChange={e => {
                  setErrorMessage(null);
                  setFirstName(e.target.value);
                }}
              />
            </div>
            <div className="form-item">
              <label className="label">Last Name *</label>
              <input
                className="input"
                type="text"
                required
                value={lastName}
                onChange={e => {
                  setErrorMessage(null);
                  setLastName(e.target.value);
                }}
              />
            </div>
            <div className="form-item">
              <label className="label">Email *</label>
              <input
                className="input"
                type="email"
                value={email}
                onChange={e => {
                  setErrorMessage(null);
                  setEmail(e.target.value);
                  //setUserName(e.target.value);
                }}
                // readOnly={isEdit ? true:false }
              />
              {email && caregiver && <p className="help">CGP email will be updated in database.</p>}
            </div>
            {isEdit && caregiver && (
              <div className="form-item">
                <label className="label">Billing email</label>
                <input
                  className="input"
                  type="email"
                  value={caregiver.billingEmail ?? ""}
                  onChange={(e) => {
                    setErrorMessage(null);
                    setCaregiver({...caregiver, billingEmail: e.target.value});
                  }}
                />
                {caregiver.billingEmail && <p className="help">Billing email will be updated in database.</p>}
              </div>
            )}
            <div className="form-item">
              <label className="label">Phone</label>
              <input
                className="input"
                type="phone"
                value={phone}
                onChange={e => {
                  setErrorMessage(null);
                  setPhone(e.target.value);
                }}
              />
            </div>

            {isEdit ? null : (
              <span>
                <div className="form-item">
                  <label className="label">Password *</label>
                  <input
                    className="input"
                    type="password"
                    value={password}
                    required
                    onChange={e => {
                      setErrorMessage(null);
                      setPassword(e.target.value);
                    }}
                  />
                </div>
                <div className="form-item">
                  <label className="label">Confirm Password *</label>
                  <input
                    className="input"
                    type="password"
                    value={confirmpassword}
                    required
                    onChange={e => {
                      setErrorMessage(null);
                      setConfirmPassword(e.target.value);
                    }}
                  />
                </div>
              </span>
            )}
          </section>
          <footer className="modal-card-foot">
            <button disabled={isSubmitting} type="submit" className="button is-primary">
              {isEdit ? "Update" : "Create"}{" "}
              {isChildren || activeGroup === "child" ? "Children" : "Caregiver"}
            </button>
          </footer>
        </div>
      </form>
    </div>
  );
};

export default ModalEditUser;
