import JoditEditor from "jodit-react";
import Multiselect from "multiselect-react-dropdown";
import Loader from "react-loader-spinner";
import AppSelect, { ValueType } from "react-select";
import { AppCategoryResponse, AppResponseLite } from "../../openapi";
import "./modal-edit-apps.sass";
import { IAppLevelOption, ISelectLabelOption, useModalEditApp } from "./hooks";
import { useMemo } from "react";
import { localCompareWithNumbers } from "../../util/helper";

interface Props {
  existingApp: AppResponseLite | undefined;
  categoryOptions: AppCategoryResponse[] | undefined;
  onClose: (refreshApps: boolean) => void;
}

const ModalEditApps = ({ existingApp, categoryOptions, onClose }: Props) => {

  const {updateApp,
    handleSubmit,
    existingAppModel,
    errorMessage,
    selectOnChangeHandler,
    removeItemHandler,
    category,
    name,
    setName,
    description,
    setDescription,
    shortDescription,
    setShortDescription,
    selectAppLevel,
    setSelectAppLevel,
    appLevelOption,
    packageName,
    formattedDescription,
    changeFormattedDescription,
    setPackageName,
    image,
    setImage,
    playStoreURL,
    setPlayStoreURL,
    developerSite,
    setDeveloperSite,
    neverDisable,
    setNeverDisable,
    hideForNewUsers,
    setHideForNewUsers,
    isBanned,
    setIsBanned,
    madePublicOn,
    onHideApp,
    onMakePublicClicked,
    isSubmitting,
    setErrorMessage,
    groupLabelsByTypes,
    appDescriptions,
    labelsState,
    setLabelsState
  } = useModalEditApp(existingApp, categoryOptions, onClose);

  const LabelsSelects = useMemo(() => {
    const groupedLabels = groupLabelsByTypes(appDescriptions);
    return Object.keys(groupedLabels)
      .sort((a: string, b: string) => localCompareWithNumbers(a, b))
      .map((labelsType) => {
        return (
          <div className="form-item" key={labelsType}>
            <label className="label">{labelsType} tag labels</label>
            <AppSelect
              value={labelsState[labelsType]}
              onChange={(chosenOption: ValueType<ISelectLabelOption, true>) => {
                setErrorMessage(null);
                setLabelsState((prevState) => ({
                  ...prevState,
                  [labelsType]: chosenOption,
                }));
              }}
              options={groupedLabels[labelsType].sort(
                (c1: ISelectLabelOption, c2: ISelectLabelOption) => {
                  return localCompareWithNumbers(c1.label, c2.label);
                }
              )}
              className="select-widget"
              classNamePrefix="select"
              isMulti={true}
            />
          </div>
        );
      });
  }, [appDescriptions, groupLabelsByTypes, labelsState, setErrorMessage, setLabelsState]);

  return (
    <div className="modal edit-app is-active">
      <div className="modal-background" onClick={() => onClose(false)}></div>
      <form onSubmit={existingApp ? updateApp : handleSubmit}>
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">{existingApp ? "Edit" : "Create"} App</p>
            <button
              className="delete"
              onClick={() => onClose(false)}
              aria-label="close"
            ></button>
          </header>
          <section className="modal-card-body">
            {existingApp !== undefined && existingAppModel === undefined ? 
              <Loader
                type="Oval"
                color="#00BFFF"
                height={50}
                width={50}
              />
              : (
                <div>
                  <div className="has-text-danger">{errorMessage}</div>
                    <div className="form-item">
                      <label className="label">Category *</label>
                      <Multiselect
                        displayValue={"name"}
                        options={categoryOptions}
                        style={{
                          chips: { background: "#E6E6E6", color: "#253858" },
                          option: { color: "#253858" },
                        }}
                        closeIcon={"cancel"}
                        avoidHighlightFirstOption={true}
                        onSelect={selectOnChangeHandler}
                        onRemove={removeItemHandler}
                        selectedValues={category}
                      />
                    </div>
                    <div className="form-item">
                      <label className="label">CGP Name *</label>
                      <input
                        className="input"
                        type="text"
                        value={name}
                        required={true}
                        onChange={(e) => {
                          setErrorMessage(null);
                          setName(e.target.value);
                        }}
                      />
                    </div>
                    <div className="form-item">
                      <label className="label">Full Name + Keywords</label>
                      <input
                        className="input"
                        type="text"
                        value={description}
                        onChange={(e) => {
                          setErrorMessage(null);
                          setDescription(e.target.value);
                        }}
                      />
                    </div>
                    <div className="form-item">
                      <label className="label">CGP Description</label>
                      <input
                        className="input"
                        type="text"
                        maxLength={50}
                        value={shortDescription}
                        onChange={(e) => {
                          setErrorMessage(null);
                          setShortDescription(e.target.value);
                        }}
                      />
                    </div>

                    {LabelsSelects}

                    <div className="form-item">
                      <label className="label">App Level*</label>
                        <AppSelect
                          value={selectAppLevel}
                          onChange={(e: ValueType<IAppLevelOption, false>) => {
                            setErrorMessage(null);
                            setSelectAppLevel(e as IAppLevelOption)
                          }}
                          options={appLevelOption}
                          className="select-widget"
                        />
                    </div>

                    {( !packageName ? <div className="form-item">
                      <label className="label">Formatted Description</label>
                      <JoditEditor
                        value={formattedDescription}
                        config={{
                          readonly: false,
                        }}
                        onBlur={changeFormattedDescription}
                      />
                    </div> :
                    "")}

                    <div className="form-item">
                      <label className="label">Package Name</label>
                      <input
                        className="input"
                        type="text"
                        value={packageName}
                        onChange={(e) => {
                          setErrorMessage(null);
                          setPackageName(e.target.value);
                        }}
                      />
                    </div>
                    <div className="form-item">
                      <label className="label">Image</label>
                      <input
                        className="input"
                        type="text"
                        value={image}
                        onChange={(e) => {
                          setErrorMessage(null);
                          setImage(e.target.value);
                        }}
                      />
                    </div>
                    <div className="form-item">
                      <label className="label">Google Play Store URL</label>
                      <input
                        className="input"
                        type="text"
                        value={playStoreURL}
                        onChange={(e) => {
                          setErrorMessage(null);
                          setPlayStoreURL(e.target.value);
                        }}
                      />
                    </div>
                    <div className="form-item">
                      <label className="label">Developer Site</label>
                      <input
                        className="input"
                        type="text"
                        value={developerSite}
                        onChange={(e) => {
                          setErrorMessage(null);
                          setDeveloperSite(e.target.value);
                        }}
                      />
                    </div>
                    <div className="form-item">
                      <label className="label">Never Disable</label>
                      <input
                        type="checkbox"
                        checked={neverDisable}
                        onChange={() => setNeverDisable(!neverDisable)}
                      />
                    </div>
                    <div className="form-item">
                      <label className="label">Hide for new user</label>
                      <input
                        type="checkbox"
                        checked={hideForNewUsers}
                        onChange={() => setHideForNewUsers(!hideForNewUsers)}
                      />
                    </div>
                    <div className="form-item">
                      <label className="label">Banned App</label>
                      <input
                        type="checkbox"
                        checked={isBanned}
                        onChange={() => setIsBanned(!isBanned)}
                      />
                    </div>
                    {madePublicOn ? (
                      <div className="form-item">
                        <label className="label">Publish this app on:</label>
                        <input type="date" value={madePublicOn} readOnly={true} />
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            onHideApp();
                          }}
                        >
                          Clear
                        </button>
                      </div>
                    ) : (
                      <div className="form-item">
                        <label className="label">Make Public</label>
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            onMakePublicClicked();
                          }}
                        >
                          Make pubilc
                        </button>
                      </div>
                    )}
                </div>
              )
            }
            
          </section>
          <footer className="modal-card-foot">
            <button
              disabled={isSubmitting}
              type="submit"
              className="button is-primary"
            >
              {existingApp ? "Update" : "Create"} App
            </button>
          </footer>
        </div>
      </form>
    </div>
  );
};

export default ModalEditApps;
