import React, { useContext, useState } from "react";
import { Auth } from "aws-amplify";
import { useHistory } from "react-router-dom";
import "./header.sass";

import { MeContext } from "../../context/me";

const Header = () => {
  const me = useContext<any>(MeContext);
  const [open, setOpen] = useState(false);
  const history = useHistory();

  const logout = async () => {
    Auth.signOut()
      .then(() => history.push("/login"))
      .catch(() => alert("Unexpected error, try again later"));
  };

  return (
    <div className="dashboard-header">
      <div className={`account ${open ? "open" : ""}`}>
        <div className="info" onClick={() => setOpen(!open)}>
          <div>
            {me.attributes ? 
            `${me.attributes["given_name"]} ${me.attributes["family_name"]}` 
             : ""}
          </div>
          <img alt="Avatar" src="https://picsum.photos/seed/5e3aaab5cc928e792ae6aac7/124" />
        </div>
        {open && (
          <div className="dropdown">
            <ul>
              <li className="logout">
                <span onClick={logout}>Log out</span>
              </li>
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;
