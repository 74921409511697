import React, { CSSProperties } from "react";
import { AppResponseLite } from "../../openapi";
import "./apps-list.sass";

interface Props {
  app: AppResponseLite;
  removeApp: () => void;
  editApp: () => void;
  style: CSSProperties;
}

export const cellHeight = 80;

const AppsListItem = ({ app, removeApp, editApp, style }: Props) => {
 
  const remove = () => {
    removeApp();
  }
  return (
    <div style={style}>
      <div style={{display: "flex", flexDirection: "row", padding: "8px", alignItems: "center", minHeight: `${cellHeight}px`}}>
        <div style={{flexGrow: 10}}>{ app.name }</div>
        <div className="buttons is-inline is-pulled-right">
          <button className="button is-text" onClick={() => editApp()}>Edit</button>
          <button onClick={() => remove()} className="button is-text remove">Remove</button>
        </div>
      </div>
      <hr style={{padding: "0px", margin: "0px"}}></hr>
    </div>
  )
};


export default AppsListItem;
