import React, { useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { Auth } from "aws-amplify";

function SignUp() {
  let history = useHistory();
  const [username, setUserName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("+8801916897606");
  const [password, setPassword] = useState("Admin1234#");
  const [confirmpassword, setConfirmPassword] = useState("Admin1234#");
  const [errorMessage, setErrorMessage] = useState("");

  const handleSignup = async () => {
    console.log(email);
    if (email && password && phone && username) {
      
      const params = {
        username,
        phone,
        password,
        confirmpassword,
        attributes: {
          email: email,
          "given_name": firstName,
          "family_name": lastName
        }
      }

      try {
        await Auth.signUp(params);
        history.push("/");
      } catch (error: any) {
        console.log(error.message);
        setErrorMessage(error.message);
      }
    } else {
      setErrorMessage("Please enter email and password");
    }
  };

  return (
    <>
      <h1 className="title">Impekable</h1>
      <p className="subtitle is-5">Please complete to create your account.</p>
      <form className="container">
        <div className="has-text-danger">{errorMessage}</div>
        <div className="form-item">
          <label className="label">User Name</label>
          <input
            className="input"
            type="text"
            value=""
            onChange={e => {
              setErrorMessage("");
              setUserName(e.target.value);
            }}
          />
        </div>
        <div className="form-item">
          <label className="label">First Name</label>
          <input
            className="input"
            type="text"
            onChange={e => {
              setErrorMessage("");
              setFirstName(e.target.value);
            }}
          />
        </div>
        <div className="form-item">
          <label className="label">Last Name</label>
          <input
            className="input"
            value="selim"
            type="text"
            onChange={e => {
              setErrorMessage("");
              setLastName(e.target.value);
            }}
          />
        </div>
        <div className="form-item">
          <label className="label">Phone</label>
          <input
            className="input"
            type="phone"
            onChange={e => {
              setErrorMessage("");
              setPhone(e.target.value);
            }}
          />
        </div>
        <div className="form-item">
          <label className="label">Email</label>
          <input
            className="input"
            type="email"
            onChange={e => {
              setErrorMessage("");
              setEmail(e.target.value);
            }}
          />
        </div>

        <div className="form-item">
          <label className="label">Password</label>
          <input
            className="input"
            type="password"
            value="Admin1234#"
            onChange={e => {
              setErrorMessage("");
              setPassword(e.target.value);
            }}
          />
        </div>
        <div className="form-item">
          <label className="label">Confirm Password</label>
          <input
            className="input"
            type="password"
            value="Admin1234#"
            onChange={e => {
              setErrorMessage("");
              setConfirmPassword(e.target.value);
            }}
          />
        </div>
        {/* <div className="form-item">
          <label className="label" htmlFor="rememberme">
            <input name="rememberme" type="checkbox" /> I agree with terms and conditions
          </label>
        </div> */}
        <div className="form-item center">
          <button className="button is-primary" onClick={handleSignup}>
            Sign up
          </button>
        </div>
        <div className="form-item center">
          <Link to="/login">Already have an account? Sign in.</Link>
        </div>
      </form>
    </>
  );
}

export default SignUp;
