import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import awsconfig from './aws-exports';
import Amplify, { Auth } from "aws-amplify";

Amplify.configure(awsconfig);
Auth.configure(awsconfig);


ReactDOM.render(
  <App />
  , document.getElementById("root"));


