import React from "react";

import "./auth-page.sass";
interface Props {
  children: React.ReactNode
}

const AuthPage = ( {children}: Props ) => {
  return (
    <div className="auth-page">
      <div className="left column is-hidden-mobile"></div>
      <div className="right column">
        <div className="center">
          {children}
        </div>
      </div>
    </div>
  );
}

export default AuthPage;
