import React from "react";
import { UserType,AttributeListType } from "aws-sdk/clients/cognitoidentityserviceprovider";

interface Props {
  handleEdit: (user: UserType) => void,
  user: UserType,
}
const UserList = ({
  handleEdit,
  user,
}: Props) => {
  // we will remove email
  const showEmail = (obj: AttributeListType) => {
    let name = "";
    for (let i = 0; i < obj!.length; i++) {
      if (obj![i].Name === "email") {
        name += obj![i].Value;
      }
    }
    return name;
  };

  const edit = (user: UserType) => {
    handleEdit(user);
  };


  return (
    <tr key={user.Username}>
      <td>
        <span className="name">{user.Username}</span>
      </td>
      <td>
        <span className="name">{user.Attributes ? showEmail(user.Attributes) : ""}</span>
      </td>
      <td>
        <div className="buttons is-inline is-pulled-right">
          <button className="button is-text" onClick={() => edit(user)}>
            Edit
          </button>
        </div>
      </td>
    </tr>
  );
};

export default UserList;
