import React, {useState, useEffect} from "react";
import AppDescriptionList from "../../components/AppDescriptionList";

import "./appDescription.sass"
import ModalEditAppDescription from "../../components/ModalEditAppDescription";
import { AdminApi, AppDescriptionResponse } from "../../openapi";
import { getServiceConfig, localCompareWithNumbers } from "../../util/helper";
import Loader from "react-loader-spinner";

const getApiService = async () => {
  return new AdminApi(await getServiceConfig());
};

interface IOptions {
  value: string,
  label: string
}

const AppDescription = () => {
  const [descriptionLists, setDescriptionLists] = useState<AppDescriptionResponse[] | []>([])
  const [search, setSearch] = useState("");
  const [isModalActive, setIsModalActive] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [editDescription, setEditDescription] = useState<AppDescriptionResponse | null>(null);
  const [typesForModalSelect, setTypesForModalSelect] = useState<IOptions[] | []>([])
  const [isLoading, setIsLoading] = useState(false);

  const sortDescriptionLists = (items: AppDescriptionResponse[]): AppDescriptionResponse[] => {
    return [...items].sort((c1, c2) => {
      return localCompareWithNumbers(`${c1.type}${c1.label}`, `${c2.type}${c2.label}`)
    });
  }

  useEffect(()=> {
    async function loadAppDescriptions() {
      setIsLoading(true);
      try {
        const apiService  = await getApiService();
        const response = await apiService.getAppDescriptions();
        setDescriptionLists(sortDescriptionLists(response.data.descriptions))
        setIsLoading(false);
      } catch (e) {
        console.log(e);
      }
    }

    loadAppDescriptions();
  }, [])

  const filteredDescriptions = descriptionLists?.filter(descripion =>
      descripion.type.toLowerCase().includes(search.toLowerCase())
  ) || [];

  const setEditForm = (description: AppDescriptionResponse): void => {
    setEditDescription(description)
    setIsEdit(true);
    setIsModalActive(true);
  };

  useEffect(()=> {
    const groupLabelsByTypes = (descriptions: AppDescriptionResponse[]) => {
      let typesObj: { [key: string]: [{ label: string, value: string }] } = {}
      descriptions.forEach(({type, label}: AppDescriptionResponse) => {
        if (typesObj.hasOwnProperty(type)) {
          typesObj[type].push({ label: label, value: label})
        } else {
          typesObj[type] = [{ label: label, value: label }]
        }
      })
      return typesObj
    }

    const groupedLabels = groupLabelsByTypes(descriptionLists)
    const uniqueTypes = Object.keys(groupedLabels).sort((a: string, b: string) => localCompareWithNumbers(a, b))

    const groupTypesForSelect = (arr: string[]): IOptions[] => {
      return arr.map(typeName => ({ value: typeName, label: typeName }))
    }

    setTypesForModalSelect(groupTypesForSelect(uniqueTypes))
  }, [descriptionLists])

  const list = filteredDescriptions.map(item => (
    <AppDescriptionList
      key={item.id}
      description={item}
      setEditDescription={setEditForm}
    />
  ))

  return (
    <div className="description-content">
      <header>
        <div className="left">
          <h2 className="title">App Description</h2>
          <button className="button is-primary" onClick={() => {
            setEditDescription(null)
            setIsModalActive(true)
            setIsEdit(false)
          }}>
            Create Description
          </button>
        </div>
        <div className="right">
          <input
              className="input"
              type="text"
              onInput={e => setSearch((e.target as HTMLInputElement).value)}
              placeholder="Search name..."
          />
        </div>
      </header>
        <div className="group">
          <table>
            <thead>
              <tr>
                <th>Type</th>
                <th>Label</th>
                <th>Level</th>
                <th className="action-header">
                  <span>Action</span>
                </th>
                </tr>
                </thead>
                <tbody>
                {isLoading ? (
                      <tr>
                        <td colSpan={4}>
                          <div className="has-text-centered">
                            <Loader type="Oval" color="#00BFFF" height={50} width={50} />
                          </div>
                        </td>
                      </tr>
                  ) : filteredDescriptions.length ? (
                    list
                ) : (
                    <tr>
                        <td colSpan={4}>no data found!</td>
                    </tr>
                )}
                </tbody>
            </table>
        </div>
        {isModalActive ? (
          <ModalEditAppDescription
            isEdit={isEdit}
            isActive={isModalActive}
            setIsModalActive={setIsModalActive}
            editCategory={editDescription}
            groupTypesForSelect={typesForModalSelect}
            />
          ) : null }
      </div>
    )
}

export default AppDescription
