import React, { FormEvent, useState } from "react";
import JoditEditor from "jodit-react";
import ReactSelect, {ValueType} from "react-select";
import CreatableSelect from 'react-select/creatable'
import { AdminApi, AppDescriptionResponse, CreateAppDesciptionRequest, UpdateAppDescriptionRequest } from "../../openapi";

import "./modal-edit-app-description.sass";
import { getServiceConfig } from "../../util/helper";

const getApiService = async () => {
  return new AdminApi(await getServiceConfig());
};

interface Props {
  isEdit: boolean;
  isActive: boolean;
  setIsModalActive: (b: boolean) => void;
  editCategory?: AppDescriptionResponse | null;
  groupTypesForSelect: IDescriptionTypeOption[]
}

interface IDescriptionTypeOption {
  label: string,
  value: string
}

export const DescriptionLevels = [
  "Low",
  "Medium",
  "High"
]

interface ILevelOption {
  label: string,
  value: number
}

const levelOption: ILevelOption[] = [
  {
    label: DescriptionLevels[0],
    value: 0
  },
  {
    label: DescriptionLevels[1],
    value: 1
  },
  {
    label: DescriptionLevels[2],
    value: 2
  },
]

const ModalEditAppDescription = ({ isEdit, isActive, setIsModalActive, editCategory, groupTypesForSelect }: Props) => {
  const [selectDescriptionType, setSelectDescriptionType] = useState<ValueType<IDescriptionTypeOption, false> | null>(
      groupTypesForSelect.find(item => (item.value).toString() === editCategory?.type) || null
  );
  const [label, setLabel] = useState<string>(editCategory?.label ? editCategory.label : "");
  const [subtitle, setSubtitle] = useState<string>(editCategory?.subtitle ?? "");
  const [description, setDescription] = useState<string>(editCategory?.description ? editCategory.description : "");
  const [recommendation, setRecommendation] = useState<string>(editCategory?.recommendation ?? "");
  const [level, setLevel] = useState<ILevelOption | null>(
      levelOption.find(item => Number(item.value) === editCategory?.level) || null
  );

  const [errors, setErrors] = useState<{ 
    [key: string]: string | undefined
   }>({});


  const handleTypeSelectChange = (
      newValue: ValueType<IDescriptionTypeOption, false>,
  ) => {
    setSelectDescriptionType(newValue)
  };

  const closeModal = () => {
    setIsModalActive(false);
  };

  const changeDescription = (newValue: string) => {
    setDescription(newValue);
  };

  const updateCategory = async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      if (
        Object.values(errors).length > 0 &&
        Object.values(errors).every((item) => item !== undefined)
      ) {
        alert("Please fix all errors.");
        return;
      }
      const apiService  = await getApiService();
      if (
        editCategory &&
        selectDescriptionType &&
        label &&
        description &&
        level
      ) {
        const updateAppDescriptionRequest: UpdateAppDescriptionRequest = {
          id: editCategory.id,
          type: selectDescriptionType.value,
          label: label,
          description,
          level: level.value,
        };
        if (subtitle) {
          updateAppDescriptionRequest.subtitle = subtitle;
        }
        if (recommendation) {
          updateAppDescriptionRequest.recommendation = recommendation;
        }
        const response = await apiService.updateAppDescription(
          updateAppDescriptionRequest
        );
        if (response.status === 200) {
          editCategory.type = selectDescriptionType.value;
          editCategory.label = label;
          editCategory.description = description;
          editCategory.level = level.value;
          closeModal();
        }
      } else {
        alert("Please provide all required data.");
      }

  }
  const createCategory = async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      if (
        Object.values(errors).length > 0 &&
        Object.values(errors).every((item) => item !== undefined)
      ) {
        alert("Please fix all errors.");
        return;
      }
      const apiService  = await getApiService();
      if (selectDescriptionType && label && description && level) {
        const createAppDescriptionData: CreateAppDesciptionRequest = {
          type: selectDescriptionType.value,
          label: label,
          description,
          level: level.value,
        };
        if (subtitle) {
          createAppDescriptionData.subtitle = subtitle;
        }
        if (recommendation) {
          createAppDescriptionData.recommendation = recommendation;
        }
        const response = await apiService.createAppDescription(
          createAppDescriptionData
        );
        if (response.status === 201) {
          closeModal();
          // todo: needs to push to the list, as a shortcut reloading the page for the time being.
          window.location.reload();
        }
      } else {
        alert("Please provide all required data.");
      }
  }

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setErrors({
      ...errors,
      [name]: undefined
    })

    switch (name) {
      case "label":
        if (value.length > 45) {
          setErrors({
            ...errors,
            label: "Label should not be more than 45 characters."
          })
          return;
        }
        setLabel(value);
        setErrors({
          ...errors,
          label: undefined
        })
        break;
      case "subtitle":
        if (value.length > 60) {
          setErrors({
            ...errors,
            subtitle: "Subtitle should not be more than 60 characters."
          })
          return;
        }
        setSubtitle(value);
        setErrors({
          ...errors,
          subtitle: undefined
        })
        break;
      default:
        break;
    }
  }

  return (
    <div className={`modal edit-description ${isActive ? "is-active" : ""}`}>
      <div className="modal-background" onClick={closeModal}></div>
      <form onSubmit={isEdit ? updateCategory : createCategory}>
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">
              {isEdit ? "Edit" : "Create"} Description Category
            </p>
            <button
              className="delete"
              onClick={closeModal}
              aria-label="close"
            ></button>
          </header>
          <section className="modal-card-body">
            <div className="form-item">
              <label className="label">Type *</label>
              <CreatableSelect
                value={selectDescriptionType}
                onChange={handleTypeSelectChange}
                options={groupTypesForSelect}
                className="select-widget"
              />
            </div>
            <div className="form-item">
              <label className="label">Label</label>
              <input
                className={`input${errors.label ? " is-danger" : ""}`}
                type="text"
                name="label"
                required
                value={label}
                onChange={handleOnChange}
              />
              <p className={`help${errors.label ? " is-danger" : ""}`}>
                {errors.label? errors.label : "Recommended maximum 34 characters"}
              </p>
            </div>
            <div className="form-item">
              <label className="label">Subtitle</label>
              <input
                className={`input${errors.subtitle ? " is-danger" : ""}`}
                type="text"
                name="subtitle"
                value={subtitle}
                onChange={handleOnChange}
              />
              <p className={`help${errors.subtitle ? " is-danger" : ""}`}>
                {errors.subtitle? errors.subtitle : "Recommended maximum 47 characters"}
              </p>
            </div>
            <div className="form-item">
              <label className="label">Description</label>
              <JoditEditor
                value={description}
                config={{
                  readonly: false,
                }}
                onBlur={changeDescription}
              />
            </div>
            <div className="form-item">
              <label className="label">Recommendation</label>
              <JoditEditor
                value={recommendation}
                config={{
                  readonly: false,
                }}
                onBlur={(newContent) => setRecommendation(newContent)}
              />
            </div>
            <div className="form-item">
              <label className="label">Level</label>
              <ReactSelect
                value={level}
                onChange={(chosen: any) => {
                  setLevel(chosen);
                }}
                options={levelOption}
                className="select-widget"
              />
            </div>
          </section>
          <footer className="modal-card-foot">
            <button type="submit" className="button is-primary">
              {isEdit ? "Update" : "Save"}
            </button>
          </footer>
        </div>
      </form>
    </div>
  );
}

export default ModalEditAppDescription
