// eslint-disable-next-line import/no-anonymous-default-export
export default {
  "Auth": {
    "mandatorySignIn": true,
    "region": "us-east-2",
    "userPoolId": process.env.REACT_APP_USER_POOL_ID,
    "userPoolWebClientId": process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID
  },
  "UserPoolInfo": {
    "accessKeyId": process.env.REACT_APP_IAM_ACCESS_KEY_ID,
    "secretAccessKey": process.env.REACT_APP_IAM_SECRET_ACCESS_KEY,
    "region": "us-east-2"
  }
}