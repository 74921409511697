import React from "react";
import { AppDescriptionResponse } from "../../openapi";
import { DescriptionLevels } from "../ModalEditAppDescription";

interface Props {
    description: AppDescriptionResponse,
    setEditDescription?: (b: AppDescriptionResponse) => void
}

const AppDescriptionList = ({ description, setEditDescription }: Props) => {
  return (
    <tr>
      <td>
        <span className="name">{ description.type }</span>
      </td>
      <td>
        <span className="name">{ description.label }</span>
      </td>
      <td>
      <span className="name">{ DescriptionLevels[description.level] }</span>
      </td>
      <td>
        <div className="buttons is-inline is-pulled-right">
          <button className="button is-text" onClick={() => setEditDescription!(description)}>Edit</button>
        </div>
      </td>
    </tr>
  )
};

export default AppDescriptionList;
