/* tslint:disable */
/* eslint-disable */
/**
 * Pinwheel Main Service Admin API
 * Pinwheel Main Service Admin API
 *
 * The version of the OpenAPI document: 2.42.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AppCategoriesResponse } from '../models';
// @ts-ignore
import { AppCategoryResponse } from '../models';
// @ts-ignore
import { AppDescriptionResponse } from '../models';
// @ts-ignore
import { AppDescriptionsResponse } from '../models';
// @ts-ignore
import { AppResponse } from '../models';
// @ts-ignore
import { AppsResponse } from '../models';
// @ts-ignore
import { AppsResponseLite } from '../models';
// @ts-ignore
import { BooleanResponse } from '../models';
// @ts-ignore
import { CaregiverResponse } from '../models';
// @ts-ignore
import { CreateAppCategoryRequest } from '../models';
// @ts-ignore
import { CreateAppDesciptionRequest } from '../models';
// @ts-ignore
import { CreateAppRequest } from '../models';
// @ts-ignore
import { FailedPaymentNotificationRequest } from '../models';
// @ts-ignore
import { ModifyAppCategoryRequest } from '../models';
// @ts-ignore
import { ModifyAppCategoryResponse } from '../models';
// @ts-ignore
import { SubscriptionCancelActionRequestDto } from '../models';
// @ts-ignore
import { SubscriptionCancelActionResponseDto } from '../models';
// @ts-ignore
import { UpdateAppCategoryRequest } from '../models';
// @ts-ignore
import { UpdateAppDescriptionRequest } from '../models';
// @ts-ignore
import { UpdateAppRequest } from '../models';
// @ts-ignore
import { UpdateCaregiverDto } from '../models';
/**
 * AdminApi - axios parameter creator
 * @export
 */
export const AdminApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateAppRequest} createAppRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createApp: async (createAppRequest: CreateAppRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createAppRequest' is not null or undefined
            assertParamExists('createApp', 'createAppRequest', createAppRequest)
            const localVarPath = `/main/admin/apps`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createAppRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateAppCategoryRequest} createAppCategoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAppCategory: async (createAppCategoryRequest: CreateAppCategoryRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createAppCategoryRequest' is not null or undefined
            assertParamExists('createAppCategory', 'createAppCategoryRequest', createAppCategoryRequest)
            const localVarPath = `/main/admin/appCategories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createAppCategoryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateAppDesciptionRequest} createAppDesciptionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAppDescription: async (createAppDesciptionRequest: CreateAppDesciptionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createAppDesciptionRequest' is not null or undefined
            assertParamExists('createAppDescription', 'createAppDesciptionRequest', createAppDesciptionRequest)
            const localVarPath = `/main/admin/appDescriptions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createAppDesciptionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppCategories: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/main/admin/appCategories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppDescriptions: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/main/admin/appDescriptions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} appIds 
         * @param {boolean} [includeCategories] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppsByIds: async (appIds: string, includeCategories?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appIds' is not null or undefined
            assertParamExists('getAppsByIds', 'appIds', appIds)
            const localVarPath = `/main/admin/appsByIds`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (appIds !== undefined) {
                localVarQueryParameter['appIds'] = appIds;
            }

            if (includeCategories !== undefined) {
                localVarQueryParameter['includeCategories'] = includeCategories;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppsLite: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/main/admin/appsLite`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCaregiver: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getCaregiver', 'id', id)
            const localVarPath = `/main/admin/caregivers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SubscriptionCancelActionRequestDto} subscriptionCancelActionRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleChildrenAfterCancellation: async (subscriptionCancelActionRequestDto: SubscriptionCancelActionRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'subscriptionCancelActionRequestDto' is not null or undefined
            assertParamExists('handleChildrenAfterCancellation', 'subscriptionCancelActionRequestDto', subscriptionCancelActionRequestDto)
            const localVarPath = `/main/admin/handleChildrenAfterCancellation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(subscriptionCancelActionRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ModifyAppCategoryRequest} modifyAppCategoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyAppCategories: async (modifyAppCategoryRequest: ModifyAppCategoryRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'modifyAppCategoryRequest' is not null or undefined
            assertParamExists('modifyAppCategories', 'modifyAppCategoryRequest', modifyAppCategoryRequest)
            const localVarPath = `/main/admin/apps/appCategories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(modifyAppCategoryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {FailedPaymentNotificationRequest} failedPaymentNotificationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendFailedPaymentNotification: async (failedPaymentNotificationRequest: FailedPaymentNotificationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'failedPaymentNotificationRequest' is not null or undefined
            assertParamExists('sendFailedPaymentNotification', 'failedPaymentNotificationRequest', failedPaymentNotificationRequest)
            const localVarPath = `/main/admin/sendFailedPaymentNotification`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(failedPaymentNotificationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateAppRequest} updateAppRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateApp: async (updateAppRequest: UpdateAppRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateAppRequest' is not null or undefined
            assertParamExists('updateApp', 'updateAppRequest', updateAppRequest)
            const localVarPath = `/main/admin/apps`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAppRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateAppCategoryRequest} updateAppCategoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAppCategory: async (updateAppCategoryRequest: UpdateAppCategoryRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateAppCategoryRequest' is not null or undefined
            assertParamExists('updateAppCategory', 'updateAppCategoryRequest', updateAppCategoryRequest)
            const localVarPath = `/main/admin/appCategories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAppCategoryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateAppDescriptionRequest} updateAppDescriptionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAppDescription: async (updateAppDescriptionRequest: UpdateAppDescriptionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateAppDescriptionRequest' is not null or undefined
            assertParamExists('updateAppDescription', 'updateAppDescriptionRequest', updateAppDescriptionRequest)
            const localVarPath = `/main/admin/appDescriptions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAppDescriptionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateCaregiverDto} updateCaregiverDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCaregiver: async (id: string, updateCaregiverDto: UpdateCaregiverDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateCaregiver', 'id', id)
            // verify required parameter 'updateCaregiverDto' is not null or undefined
            assertParamExists('updateCaregiver', 'updateCaregiverDto', updateCaregiverDto)
            const localVarPath = `/main/admin/caregivers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCaregiverDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminApi - functional programming interface
 * @export
 */
export const AdminApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateAppRequest} createAppRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createApp(createAppRequest: CreateAppRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createApp(createAppRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateAppCategoryRequest} createAppCategoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAppCategory(createAppCategoryRequest: CreateAppCategoryRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppCategoryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAppCategory(createAppCategoryRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateAppDesciptionRequest} createAppDesciptionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAppDescription(createAppDesciptionRequest: CreateAppDesciptionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppDescriptionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAppDescription(createAppDesciptionRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAppCategories(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppCategoriesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAppCategories(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAppDescriptions(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppDescriptionsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAppDescriptions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} appIds 
         * @param {boolean} [includeCategories] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAppsByIds(appIds: string, includeCategories?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAppsByIds(appIds, includeCategories, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAppsLite(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppsResponseLite>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAppsLite(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCaregiver(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CaregiverResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCaregiver(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SubscriptionCancelActionRequestDto} subscriptionCancelActionRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async handleChildrenAfterCancellation(subscriptionCancelActionRequestDto: SubscriptionCancelActionRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SubscriptionCancelActionResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.handleChildrenAfterCancellation(subscriptionCancelActionRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ModifyAppCategoryRequest} modifyAppCategoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async modifyAppCategories(modifyAppCategoryRequest: ModifyAppCategoryRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ModifyAppCategoryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.modifyAppCategories(modifyAppCategoryRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {FailedPaymentNotificationRequest} failedPaymentNotificationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendFailedPaymentNotification(failedPaymentNotificationRequest: FailedPaymentNotificationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendFailedPaymentNotification(failedPaymentNotificationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateAppRequest} updateAppRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateApp(updateAppRequest: UpdateAppRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateApp(updateAppRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateAppCategoryRequest} updateAppCategoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAppCategory(updateAppCategoryRequest: UpdateAppCategoryRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppCategoryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAppCategory(updateAppCategoryRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateAppDescriptionRequest} updateAppDescriptionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAppDescription(updateAppDescriptionRequest: UpdateAppDescriptionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppDescriptionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAppDescription(updateAppDescriptionRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateCaregiverDto} updateCaregiverDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCaregiver(id: string, updateCaregiverDto: UpdateCaregiverDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCaregiver(id, updateCaregiverDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminApi - factory interface
 * @export
 */
export const AdminApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateAppRequest} createAppRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createApp(createAppRequest: CreateAppRequest, options?: any): AxiosPromise<AppResponse> {
            return localVarFp.createApp(createAppRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateAppCategoryRequest} createAppCategoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAppCategory(createAppCategoryRequest: CreateAppCategoryRequest, options?: any): AxiosPromise<AppCategoryResponse> {
            return localVarFp.createAppCategory(createAppCategoryRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateAppDesciptionRequest} createAppDesciptionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAppDescription(createAppDesciptionRequest: CreateAppDesciptionRequest, options?: any): AxiosPromise<AppDescriptionResponse> {
            return localVarFp.createAppDescription(createAppDesciptionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppCategories(options?: any): AxiosPromise<AppCategoriesResponse> {
            return localVarFp.getAppCategories(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppDescriptions(options?: any): AxiosPromise<AppDescriptionsResponse> {
            return localVarFp.getAppDescriptions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} appIds 
         * @param {boolean} [includeCategories] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppsByIds(appIds: string, includeCategories?: boolean, options?: any): AxiosPromise<AppsResponse> {
            return localVarFp.getAppsByIds(appIds, includeCategories, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppsLite(options?: any): AxiosPromise<AppsResponseLite> {
            return localVarFp.getAppsLite(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCaregiver(id: string, options?: any): AxiosPromise<CaregiverResponse> {
            return localVarFp.getCaregiver(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SubscriptionCancelActionRequestDto} subscriptionCancelActionRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleChildrenAfterCancellation(subscriptionCancelActionRequestDto: SubscriptionCancelActionRequestDto, options?: any): AxiosPromise<SubscriptionCancelActionResponseDto> {
            return localVarFp.handleChildrenAfterCancellation(subscriptionCancelActionRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ModifyAppCategoryRequest} modifyAppCategoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyAppCategories(modifyAppCategoryRequest: ModifyAppCategoryRequest, options?: any): AxiosPromise<ModifyAppCategoryResponse> {
            return localVarFp.modifyAppCategories(modifyAppCategoryRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FailedPaymentNotificationRequest} failedPaymentNotificationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendFailedPaymentNotification(failedPaymentNotificationRequest: FailedPaymentNotificationRequest, options?: any): AxiosPromise<BooleanResponse> {
            return localVarFp.sendFailedPaymentNotification(failedPaymentNotificationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateAppRequest} updateAppRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateApp(updateAppRequest: UpdateAppRequest, options?: any): AxiosPromise<AppResponse> {
            return localVarFp.updateApp(updateAppRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateAppCategoryRequest} updateAppCategoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAppCategory(updateAppCategoryRequest: UpdateAppCategoryRequest, options?: any): AxiosPromise<AppCategoryResponse> {
            return localVarFp.updateAppCategory(updateAppCategoryRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateAppDescriptionRequest} updateAppDescriptionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAppDescription(updateAppDescriptionRequest: UpdateAppDescriptionRequest, options?: any): AxiosPromise<AppDescriptionResponse> {
            return localVarFp.updateAppDescription(updateAppDescriptionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateCaregiverDto} updateCaregiverDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCaregiver(id: string, updateCaregiverDto: UpdateCaregiverDto, options?: any): AxiosPromise<BooleanResponse> {
            return localVarFp.updateCaregiver(id, updateCaregiverDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminApi - object-oriented interface
 * @export
 * @class AdminApi
 * @extends {BaseAPI}
 */
export class AdminApi extends BaseAPI {
    /**
     * 
     * @param {CreateAppRequest} createAppRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public createApp(createAppRequest: CreateAppRequest, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).createApp(createAppRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateAppCategoryRequest} createAppCategoryRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public createAppCategory(createAppCategoryRequest: CreateAppCategoryRequest, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).createAppCategory(createAppCategoryRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateAppDesciptionRequest} createAppDesciptionRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public createAppDescription(createAppDesciptionRequest: CreateAppDesciptionRequest, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).createAppDescription(createAppDesciptionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getAppCategories(options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).getAppCategories(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getAppDescriptions(options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).getAppDescriptions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} appIds 
     * @param {boolean} [includeCategories] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getAppsByIds(appIds: string, includeCategories?: boolean, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).getAppsByIds(appIds, includeCategories, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getAppsLite(options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).getAppsLite(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getCaregiver(id: string, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).getCaregiver(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SubscriptionCancelActionRequestDto} subscriptionCancelActionRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public handleChildrenAfterCancellation(subscriptionCancelActionRequestDto: SubscriptionCancelActionRequestDto, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).handleChildrenAfterCancellation(subscriptionCancelActionRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ModifyAppCategoryRequest} modifyAppCategoryRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public modifyAppCategories(modifyAppCategoryRequest: ModifyAppCategoryRequest, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).modifyAppCategories(modifyAppCategoryRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FailedPaymentNotificationRequest} failedPaymentNotificationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public sendFailedPaymentNotification(failedPaymentNotificationRequest: FailedPaymentNotificationRequest, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).sendFailedPaymentNotification(failedPaymentNotificationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateAppRequest} updateAppRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public updateApp(updateAppRequest: UpdateAppRequest, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).updateApp(updateAppRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateAppCategoryRequest} updateAppCategoryRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public updateAppCategory(updateAppCategoryRequest: UpdateAppCategoryRequest, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).updateAppCategory(updateAppCategoryRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateAppDescriptionRequest} updateAppDescriptionRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public updateAppDescription(updateAppDescriptionRequest: UpdateAppDescriptionRequest, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).updateAppDescription(updateAppDescriptionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UpdateCaregiverDto} updateCaregiverDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public updateCaregiver(id: string, updateCaregiverDto: UpdateCaregiverDto, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).updateCaregiver(id, updateCaregiverDto, options).then((request) => request(this.axios, this.basePath));
    }
}
