import React from "react";
import { useHistory } from "react-router-dom";

function ForgotPassword() {
  let history = useHistory();

  return (
    <>
      <h1 className="title">Impekable</h1>
      <p className="subtitle is-5">Enter your email and we send you a password reset link</p>
      <form className="container">
        <div className="form-item">
          <label className="label">Email</label>
          <input className="input" type="email" />
        </div>

        <div className="form-item buttons">
          <button className="button is-primary" onClick={() => history.push("/")}>
            Send request
          </button>
        </div>
      </form>
    </>
  );
}

export default ForgotPassword;
