import React, { useEffect, useState, useContext, useCallback } from "react";
import CategoryList from "../../components/CategoryList";
import ModalEditCategory from "../../components/ModalEditCategory";
import { MeContext } from "../../context/me";
import Loader from "react-loader-spinner";
import "./category.sass";
import {AdminApi, AppCategoryResponse} from "../../openapi";
import {getServiceConfig} from "../../util/helper";

const getAppCategoriesService = async() => {
  return new AdminApi(await getServiceConfig())
}

const Category = () => {
  const me = useContext(MeContext);
  const [categoryList, setCategoryList] = useState<AppCategoryResponse[] | undefined>(undefined);
  const [search, setSearch] = useState("");
  const [isModalActive, setIsModalActive] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [editCategory, setEditCategory] = useState<AppCategoryResponse | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(true);

  const sortCategoryResponse = (items: AppCategoryResponse[]) => {
    return [...items].sort((c1, c2) => {
      return c1.name.toLowerCase().localeCompare(c2.name.toLowerCase());
    });
  }

  const removeCategory = (category: AppCategoryResponse) => {
    if (window.confirm("Are you sure want to delete this?")) {
      alert("Contact Rubel or Brian Bauman to delete a category.");
    }
  };

  const filteredCategory = categoryList?.filter(category =>
    category.name.toLowerCase().includes(search.toLowerCase())
  ) || [];

  const setEditForm = (appsCategory: AppCategoryResponse) => {
    setEditCategory({ ...appsCategory });
    setIsEdit(true);
    setIsModalActive(true);
  };

  const resetAll = () => {
    setEditCategory(undefined);
    setIsEdit(false);
  };

  const list = filteredCategory.map(category => (
    <CategoryList
      key={category.name}
      category={category}
      setEditForm={setEditForm}
      removeCategory={removeCategory}
    />
  ));

  const getCategory = useCallback(async () => {
    if (!categoryList) {
      setIsLoading(true);
      try {
        const appsCategoryService = await getAppCategoriesService();
        const categoryData = await appsCategoryService.getAppCategories();
        setCategoryList(sortCategoryResponse(categoryData.data.categories));
        setIsLoading(false)
      } catch (error) {
        console.log(error);
      }
    }
  }, [categoryList]);

  const refreshCategories = () => {
    setCategoryList(undefined);
    getCategory()
  }

  useEffect(() => {
    if (!me) return;

    getCategory();
  }, [me, getCategory]);

  return (
    <div className="contacts-content">
      <header>
        <div className="left">
          <h2 className="title">Category</h2>
          <button className="button is-primary" onClick={() => setIsModalActive(true)}>
            Create Category
          </button>
        </div>
        <div className="right">
          <input
            className="input"
            type="text"
            onInput={e => setSearch((e.target as HTMLInputElement).value)}
            placeholder="Search name..."
          />
        </div>
      </header>
      <div>
        <div className="group">
          <table>
            <thead>
              <tr>
                <th>Category Name</th>
                <th className="action-header">
                  <span>Action</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                <tr>
                  <td colSpan={2}>
                    <div className="has-text-centered">
                      <Loader type="Oval" color="#00BFFF" height={50} width={50} />
                    </div>
                  </td>
                </tr>
              ) : filteredCategory.length ? (
                list
              ) : (
                <tr>
                  <td colSpan={2}>no data found!</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      {isModalActive && (
        <ModalEditCategory
          isEdit={isEdit}
          isActive={isModalActive}
          setIsModalActive={setIsModalActive}
          refreshCategories={refreshCategories}
          editCategory={editCategory}
          resetAll={resetAll}
          setIsLoading={setIsLoading}
        />
      )}
    </div>
  );
};

export default Category;
