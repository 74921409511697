import React, { useState } from "react";
import { NavLink } from "react-router-dom";

import "./navigation.sass";

interface Props {
  title: string
}

const Navigation = ({ title }: Props) => {
  const [navOpen, setNavOpen] = useState(false);

  const toggleMenu = () => {
    setNavOpen(!navOpen);
  };

  return (
    <nav className="navbar" role="navigation" aria-label="main navigation">
      <div className="navbar-brand">
        <NavLink exact={true} className="navbar-item logo" to="/">
          &nbsp;
        </NavLink>
        <div className="navbar-item is-hidden-desktop">{title}</div>
        <div
          role="button"
          className={`navbar-burger ${navOpen && "is-active"}`}
          onClick={toggleMenu}
          aria-label="menu"
          aria-expanded="false"
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </div>
      </div>
      <div className={`navbar-menu ${navOpen && "is-active"}`}>
        <ul>
          <li>
            <NavLink exact={true} className="navbar-item home" onClick={toggleMenu} to="/">
              Dashboard
            </NavLink>
          </li>
          {/* <li>
            <NavLink
              exact={true}
              className="navbar-item calendar"
              onClick={toggleMenu}
              to="/calendar"
            >
              Calendar
            </NavLink>
          </li>
          <li>
            <span className="navbar-item phone">Phone</span>
          </li> */}
          {/* <li>
            <NavLink
              className="navbar-item secondary days-modes"
              onClick={toggleMenu}
              to="/days-modes"
            >
              Days & Modes
            </NavLink>
          </li>
          <li>
            <NavLink
              exact={true}
              className="navbar-item secondary on-it"
              onClick={toggleMenu}
              to="/on-it"
            >
              Routines
            </NavLink>
          </li> */}
         
          <li>
            <NavLink
              exact={true}
              className="navbar-item secondary contacts"
              onClick={toggleMenu}
              to="/caregiver"
            >
              Caregiver
            </NavLink>
          </li>
          <li>
            <NavLink
              exact={true}
              className="navbar-item secondary at-it"
              onClick={toggleMenu}
              to="/apps"
            >
              Apps
            </NavLink>
          </li>
          <li>
            <NavLink
              exact={true}
              className="navbar-item secondary at-it"
              onClick={toggleMenu}
              to="/app-category"
            >
              App Category
            </NavLink>
          </li>
          <li>
            <NavLink
                exact={true}
                className="navbar-item secondary at-it"
                onClick={toggleMenu}
                to="/app-description"
            >
              App Description
            </NavLink>
          </li>
          {/* <br/>
          <center>
            <button color="red"
              className="navbar-item secondary at-it"
              onClick={syncProdDefault}
            >
              Sync prod default content
            </button>
          </center>   */}
        </ul>
      </div>
    </nav>
  );
};

export default Navigation;
