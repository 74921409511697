import React, { useEffect, useState, useContext, useCallback } from "react";
import AppsListItem, { cellHeight } from "../../components/AppsList";
import ModalEditApps from "../../components/ModalEditApps";
import Loader from "react-loader-spinner";
import { MeContext } from "../../context/me";
import "./apps.sass";
import { getServiceConfig } from "../../util/helper";
import { AdminApi, AppCategoryResponse, AppResponseLite } from "../../openapi";
import Select, { ValueType } from "react-select";
import { FixedSizeList } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";

const optionsFilters = [
  {
    value: 1,
    label: "All",
  },
  {
    value: 2,
    label: "Uncategorized",
  },
  {
    value: 3,
    label: "Public",
  },
  {
    value: 4,
    label: "Not public",
  },
  // {
  //   value: 5,
  //   label: "Missing Description",
  // },
  // {
  //   value: 6,
  //   label: "Long Short Description",
  // },
];

const Apps = () => {
  const me = useContext(MeContext);
  const [appsList, setAppsList] = useState<AppResponseLite[] | undefined>();
  const [appCategoriesResponse, setAppCategoriesResponse] = useState<AppCategoryResponse[] | undefined>();
  const [search, setSearch] = useState("");
  const [filteredAppsList, setFilteredAppsList] = useState<AppResponseLite[]>([]);
  const [isModalActive, setIsModalActive] = useState(false);
  const [selectedApp, setSelectedApp] = useState<AppResponseLite | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedFilter, setSelectedFilter] = useState<ValueType<{ value: number; label: string }, false>>(optionsFilters[0]);

  const getApiService = async () => {
    return new AdminApi(await getServiceConfig());
  };

  const sortAppsList = (items: AppResponseLite[]) => {
    return [...items].sort((a1, a2) => {
      return a1.name.toLowerCase().localeCompare(a2.name.toLowerCase());
    });
  };

  const refreshApps = () => {
    setAppsList(undefined);
  };

  const getAppsList = useCallback(async () => {
    try {
      const appsService = await getApiService();
      const appsData = await appsService.getAppsLite();
      const sortedAppsList = sortAppsList(appsData.data.apps);
      setAppsList(sortedAppsList);
      setFilteredAppsList(sortedAppsList);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      throw error;
    }
  }, []);

  const getCategoriesList = useCallback(async () => {
    try {
      const appsCategoryService = await getApiService();
      const categoryData = await appsCategoryService.getAppCategories();
      setAppCategoriesResponse(categoryData.data.categories);
    } catch (error) {
      console.log(error);
      throw error;
    }
  }, []);

  useEffect(() => {
    if (!appsList) return;
    let filteredList: AppResponseLite[];
    const lowerCaseSearch = search.toLowerCase();
    switch (selectedFilter?.label) {
      case "Public":
        filteredList = appsList.filter(app => app.name.toLowerCase().includes(lowerCaseSearch) && app.public && app.catIds.length > 0);
        break;
      case "Not public":
        filteredList = appsList.filter(app => app.name.toLowerCase().includes(lowerCaseSearch) && (!app.public || app.catIds.length === 0));
        break;
      case "Uncategorized":
        filteredList = appsList.filter(app => app.name.toLowerCase().includes(lowerCaseSearch) && !app.catIds.length);
        break;
      // case "Missing Description":
      //   filteredList = appsList.filter(
      //     app => app.name.toLowerCase().includes(lowerCaseSearch) && (app.description == null || app.formattedDescription == null),
      //   );
      //   break;
      // case "Long Short Description":
      //   filteredList = appsList.filter(
      //     app => ((app.shortDescription ?? "").length) > 50
      //   );
      //   break;
      default:
        filteredList = appsList.filter(app => app.name.toLowerCase().includes(lowerCaseSearch));
        break;
    }
    setFilteredAppsList(filteredList);
  }, [search, selectedFilter, appsList]);

  const removeApp = () => {
    if (window.confirm("Are you sure want to delete this?")) {
      alert(
        "DO NOT remove an app from the boutique without talking to Rubel or Brian Bauman.  This will cause MAJOR problems for existing users with the app",
      );
    }
  };

  const showEditDialog = async (app: AppResponseLite | undefined) => {
    setSelectedApp(app)
    setIsModalActive(true);
  };

  const handleDialogClose = (shouldRefreshApps: boolean) => {
    if (shouldRefreshApps) {
      refreshApps();
    }
    setIsModalActive(false);
    setSelectedApp(undefined);
  };

  useEffect(() => {
    if (!me) return;
    setAppsList(undefined);
  }, [me]);

  useEffect(() => {
    if (!appsList) {
      getAppsList();
    }
  }, [appsList, getAppsList]);

  useEffect(() => {
    if (!appCategoriesResponse) {
      getCategoriesList();
    }
  }, [appCategoriesResponse, getCategoriesList]);

  const onSortChanged = (selectedFilter: ValueType<{ value: number; label: string }, false>) => {
    setSelectedFilter(selectedFilter);
  };

  return (
    <div className="apps-content">
      <header>
        <div className="left">
          <h2 className="title">Apps</h2>
          <button
            className="button is-primary"
            onClick={() => showEditDialog(undefined)}
          >
            Create Apps
          </button>
        </div>
        <div className="right search">
          <input
            className="input"
            type="text"
            onInput={(e) => setSearch((e.target as HTMLInputElement).value)}
            placeholder="Search name..."
          />
          <Select
            value={selectedFilter}
            onChange={(selectedFilter) => onSortChanged(selectedFilter)}
            className="select-filter"
            options={optionsFilters}
            isSearchable={false}
            placeholder={"Choose a filter..."}
          />
        </div>
      </header>
      <div className="group">
        <div className="header">
          <div>Apps Name ({filteredAppsList.length})</div>
          <div>Actions</div>
        </div>
        <div className="table">
          {isLoading ? (
            <div>
              <Loader
                type="Oval"
                color="#00BFFF"
                height={50}
                width={50}
              />
            </div>
            ) : filteredAppsList.length ? (
              <div className="virtual-table">
                
                <AutoSizer>
                  {({ height, width }: { height: number, width: number}) => (
                    <div>
                      <FixedSizeList
                        itemData={filteredAppsList}
                        itemCount={filteredAppsList.length}
                        height={height}
                        width={width}
                        itemSize={cellHeight}
                      >
                        {({ data, index, style }) => {
                          const app = data[index];
                          return (
                            <AppsListItem
                              key={app.id}
                              app={app}
                              editApp={() => showEditDialog(app)}
                              removeApp={removeApp}
                              style={style}
                            />
                          );
                        }}
                      </FixedSizeList>
                    </div>
                  )}
                </AutoSizer>
              </div>
            ) : <div>no data found!</div>
          }

        </div>
      </div>

      {isModalActive && (
        <ModalEditApps
          existingApp={selectedApp}
          categoryOptions={appCategoriesResponse}
          onClose={handleDialogClose}
        />
      )}
    </div>
  );
};

export default Apps;
