import AWS from 'aws-sdk';
import awsconfig from '../aws-exports';
import { AttributeListType } from 'aws-sdk/clients/cognitoidentityserviceprovider';

AWS.config.update(awsconfig.UserPoolInfo);
const CognitoIdentityServiceProvider = AWS.CognitoIdentityServiceProvider;
export const userPoolClient = new CognitoIdentityServiceProvider({
  region: awsconfig.UserPoolInfo.region, 
});

const params = {
  UserPoolId: awsconfig.Auth.userPoolId as string,
};

export const addUserToGroup = (
  option: { Username: string , GroupName: string },
  func: (err: AWS.AWSError, data: {}) => void
  )=>{
  return userPoolClient.adminAddUserToGroup({
    ...params,
    ...option,
  }, func);
}

export const updateUser = (
  option: { Username: string , UserAttributes: AttributeListType},
  func: (err: AWS.AWSError, data: {}) => void
  )=>{
  console.log({
    ...params,
    ...option,
  })
  return userPoolClient.adminUpdateUserAttributes({
    ...params,
    ...option,
  }, func);
}

export const getUserList = (
  option: { Limit: number , GroupName: string },
  func: (_ : unknown, data: AWS.CognitoIdentityServiceProvider.Types.ListUsersResponse ) => void
  ) => {
  return userPoolClient.listUsersInGroup({
    ...params,
    ...option,
  }, func);
}

export const listUsers =(
  option: {Limit: number, Filter: string},
  func: ( _ : unknown, data: AWS.CognitoIdentityServiceProvider.Types.ListUsersResponse ) => void
  ) => {
  return userPoolClient.listUsers({
    ...params,
    ...option,
  }, func);
}

export const getUserGroups =(option: any,func: any)=>{
  return userPoolClient.listGroups({
    ...params,
    ...option,
  }, func);
}
