import React from "react";

import Navigation from "../../components/Navigation";
import Header from "../../components/Header";

interface Props {
  children: React.ReactNode | React.ReactElement,
  title: string
}

const  DashboardPage = ( {children, title}: Props ) => {
  return (
    <div className="columns">
      <div className="column is-2 sidebar">
        <Navigation title={title} />
      </div>
      <div className="column content">
        <Header />
        {children}
      </div>
    </div>
  );
}

export default DashboardPage;
