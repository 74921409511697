import React, { useEffect, useState, useContext, ChangeEvent } from "react";
import {
  getUserList,
  getUserGroups,
  listUsers,
} from "../../aws/userpool";
import UserList from "../../components/UserList";
import ModalEditUser from "../../components/ModalEditUser";
import { MeContext } from "../../context/me";
import Loader from "react-loader-spinner";
import "./users.sass";
import { GroupListType, GroupType, UsersListType, UserType } from "aws-sdk/clients/cognitoidentityserviceprovider";

interface EditType extends UserType {
  password: string,
  confirmpassword: string}

const Users = () => {

  const searchKeyList = [
    {
      key:'username',
      value:"username"
    },{
      key:"email",
      value:"email"
    },
    {
      key:"phone_number",
      value:"phone number"
    },
  ]
  const me = useContext(MeContext);
  const [userList, setUserList] = useState< UsersListType | undefined>();
  const [userGroups, setUserGroups] = useState<GroupListType>([]);
  const [editUser, setEditUser] = useState({});
  const [activeGroup, setActiveGroup] = useState("caregiver");
  const [isModalActive, setIsModalActive] = useState(false);
  const [isChildren, setIsChildren] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [searchBy, setSearchBy] = useState("username");
  const [searchKey, setSearchKey] = useState("");

  const UsersList = (groupName: string) =>
    getUserList({ GroupName: groupName, Limit:60}, ( _ : unknown, data: AWS.CognitoIdentityServiceProvider.Types.ListUsersResponse ) => {
      setUserList(data.Users);
      setIsLoading(false);
    });

  const SearchUser = ()=>{
    if(searchKey && searchBy){
      listUsers({Limit:60,
      Filter: `${searchBy}^="${searchKey}"`,}, ( _ : unknown, data: AWS.CognitoIdentityServiceProvider.Types.ListUsersResponse ) => {
        setUserList(data.Users);
        setIsLoading(false);
      });
    }
  }

  const UserGroupList = () =>
    getUserGroups({}, (_: unknown, data: AWS.CognitoIdentityServiceProvider.ListGroupsResponse) => {
      if (data.Groups) setUserGroups(data.Groups);
    });

  const setEditForm = (user: UserType) => {
    setEditUser({ ...user });
    setIsModalActive(true);
  };

  const resetAll = () => {
    setEditUser({});
    setIsChildren(false);
  };

  const list = ( userList ? userList.map(user => (
    <UserList
      handleEdit={setEditForm}
      key={user.Username}
      user={user}
    />
  )) : []);

  // we will create a option component
  const groupOption = userGroups.map((group: GroupType, index: number) => (
    <option key={index} value={group.GroupName}>
      {group.GroupName}
    </option>
  ));

  const searchOption = searchKeyList.map((item, index) => (
    <option key={index} value={item.key}>
      {item.value}
    </option>
  ));

  useEffect(() => {
    if (!me) return;
    UsersList("caregiver");
    UserGroupList();
  }, [me]);

  const setGroup = (e: ChangeEvent<HTMLSelectElement>) => {
    setIsLoading(true);
    setActiveGroup(e.target.value);
    UsersList(e.target.value);
  };

  return (
    <div className="contacts-content">
      <header>
        <div className="left">
          <h2 className="title">Caregiver</h2>
        </div>
        <div className="right">
        <form onSubmit={(e)=>{
          SearchUser()
          e.preventDefault();
        }}>
          <select className="input" onChange={setGroup} name="group" id="group">
            {groupOption}
          </select>
          <select className="input" onChange={(e)=>{
            setSearchBy(e.target.value)
          }} name="group" id="group">
            {searchOption}
          </select>
          <input
            className="input"
            type="text"
            onInput={e => setSearchKey(e.currentTarget.value)}
            placeholder="Search "
          />
          </form>
        </div>
      </header>
      <div>
        <div className="group">
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th className="has-text-centered">Action</th>
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                <tr>
                  <td colSpan={3}>
                    <div className="has-text-centered">
                      <Loader type="Oval" color="#00BFFF" height={50} width={50} />
                    </div>
                  </td>
                </tr>
              ) : userList !== undefined && userList.length ? (
                list
              ) : (
                <tr>
                  <td colSpan={3}>no data found!</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      {isModalActive ? (
        <ModalEditUser
          getUserList={UsersList}
          isChildren={isChildren}
          editUser={editUser as EditType}
          isActive={isModalActive}
          setIsModalActive={setIsModalActive}
          resetAll={resetAll}
          activeGroup={activeGroup}
          setIsLoading={setIsLoading}
          isEdit
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default Users;
