/* tslint:disable */
/* eslint-disable */
/**
 * Pinwheel Main Service Admin API
 * Pinwheel Main Service Admin API
 *
 * The version of the OpenAPI document: 2.17.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const AppLevel = {
    CoreApps: 'CoreApps',
    PinwheelApproved: 'PinwheelApproved',
    SlightlyOutOfBounds: 'SlightlyOutOfBounds',
    ViolatesGuidelines: 'ViolatesGuidelines',
    UntestedByPinwheel: 'UntestedByPinwheel'
} as const;

export type AppLevel = typeof AppLevel[keyof typeof AppLevel];



