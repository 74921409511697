import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Auth } from "aws-amplify";

export const MeContext = React.createContext({});

interface Props {
  children: React.ReactNode
}

export const MeProvider = ({ children }: Props) => {
  const [me, setMe] = useState({});
  const history = useHistory();

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then(data => {
        setMe(data);
      })
      .catch(() => history.push("/login"));
  }, [history]);
  return <MeContext.Provider value={me}>{children}</MeContext.Provider>;
};

export default MeProvider;
